



















































import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import Component, { mixins } from 'vue-class-component';
import ImageTilesComponent from '@/components/ImageTilesComponent.vue';
import { Prop } from 'vue-property-decorator';
import { Data } from '@/utils/types/WidgetData';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import GqlEntityOrderingType from '@/utils/enums/gql/GqlEntityOrderingType';
import EntityType from '@/utils/enums/EntityType';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { deepSearch } from '@/utils/ObjectHelpers';

@Component({
  components: {
    ButtonComponent,
    ImageTilesComponent,
  },
})
export default class DynamicTileListWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({ required: false, default: () => [] })
  private readonly data!: Data[];

  @Prop({ required: false, default: null })
  private readonly type!: string;

  @Prop({ required: false, default: null })
  private readonly url!: string;

  @Prop({ required: false, default: null })
  private readonly filter!: string;

  @Prop({ required: false, default: null })
  private readonly orderBy!: string;

  @Prop({ required: false, default: 0 })
  private readonly amount!: number;

  @Prop({ required: false, default: null })
  private readonly title!: string | null;

  @Prop({ required: false, default: null })
  private readonly subtitle!: string | null;

  @Prop({ required: false, default: null })
  private readonly btnTitle!: string;

  @Prop({ required: false, default: null })
  private readonly btnRoute!: string;

  private get handleBaseUrl(): string {
    if (this.btnRoute) {
      let r = this.btnRoute;
      const matches = this.btnRoute.match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          if (this.$route.params[prop]) {
            r = r.replaceAll(m, this.$route.params[prop]);
          }
        });
      }
      return r;
    }
    return '';
  }

  created(): void {
    if (this.payload) {
      const {
        type, orderBy, filter, amount,
      } = this.payload;
      let filterType = '';
      let orderType = '';
      let operation = '';
      let fragmentName = '';
      switch (type) {
        case EntityType.CHANNEL:
          filterType = GqlEntityFilterType.CHANNEL_FILTER;
          orderType = GqlEntityOrderingType.CHANNEL_ORDERING;
          operation = 'channel';
          fragmentName = 'channelBaseFragment';
          break;
        case EntityType.CATEGORY:
          filterType = GqlEntityFilterType.CATEGORY_FILTER;
          orderType = GqlEntityOrderingType.CATEGORY_ORDERING;
          operation = 'category';
          fragmentName = 'categoryBaseFragment';
          break;
        default:
          break;
      }
      let order = {};
      if (orderBy
        && (orderBy as string).length > 0
        && !(orderBy as string).includes('default')) {
        order = { orderBy: { value: (orderBy as string).split(','), type: orderType } };
      }

      let first = {};
      if (amount && (amount as number) > 0) {
        first = { first: amount };
      }

      let variables = {};
      if (filter) {
        variables = { filter: { value: JSON.parse((filter as string) || '{}'), type: filterType } };
      }
      this.setDataConfig([{
        gqlDefinition: buildQueryDefinition({
          cacheable: !this.authUser
              || (!deepSearch(variables, this.authUser.uid)
                  && !deepSearch(variables, '%authUser%')),
          ...variables,
          ...order,
          ...first,
        }),
        operation,
        fragmentName,
        alias: this.storeName,
      }]);
    } else if (this.widget) {
      this.setDataConfig(
        undefined,
        true,
      );
    }
  }
}
